/**
 * Created by preference on 2020/12/03
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 5.3.产品出入库明细  */
  get_stock_inventoryDetail: params => {
    return API.POST('api/stock/inventoryDetail', params)
  },
  /** 5.4.仓库实时库存列表  */
  get_stock_list_entityProductList: params => {
    return API.POST('api/stock/entityProductList', params)
  },
  /**  5.5.仓库实时库存列表(包含全部有效产品) */
  get_stock_list_entityProductListAll: params => {
    return API.POST('api/stock/entityProductListAll', params)
  },
  /** 5.6.仓库全部库存，不分页  */
  get_stock_list_entityProductAll: params => {
    return API.POST('api/stock/entityProductAll', params)
  },
  // 统计
  exportInventoryDetailExcel: params => {
    return API.exportExcel('api/stock/inventoryDetailExcel', params)
  },

}